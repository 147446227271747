@font-face {
  font-family: "AvenirLT35Light";
  src: local("AvenirLT35Light"),
    url(./fonts/Avenir_LT_35_Light.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "AvenirLT85Heavy";
  src: local("AvenirLT85Heavy"),
    url(./fonts/Avenir_LT_85_Heavy.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                                'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "Geometria";
  src: local("Geometria"), url(./fonts/Geometria.ttf) format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Geometria";
  src: local("Geometria"), url(./fonts/Geometria-Bold.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Geometria";
  src: local("Geometria"), url(./fonts/Geometria-Light.ttf) format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Geometria";
  src: local("Geometria"),
    url(./fonts/Geometria-ExtraBold.ttf) format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Geometria";
  src: local("Geometria"), url(./fonts/Geometria-Italic.ttf) format("truetype");
  font-style: italic;
}

/* 
@font-face {
  font-family: "DejaVu Sans";
  src: url("fonts/DejaVuSans.ttf");
}
@font-face {
  font-family: "DejaVu Sans";
  src: url("fonts/DejaVuSans-Bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "DejaVu Sans";
  src: url("fonts/DejaVuSans-Oblique.ttf");
  font-style: italic, oblique;
}
@font-face {
  font-family: "DejaVu Sans";
  src: url("fonts/DejaVuSans-BoldOblique.ttf");
  font-weight: bold;
  font-style: italic, oblique; 
}*/
